<template>
  <section class="section level">
    <h2>{{ $t("level_section.title") }}</h2>

    <div class="level-types">
      <BaseSelect
        :options="levelsList"
        :model-value="level"
        class="level__select"
        styled
        arrow
        @change="changeLevel"
      >
        <template #value>
          <span class="capitalize">{{ $t(level.value) }}</span>
        </template>
        <template #option="levelsList">
          {{ $t(levelsList.option.value) }}
        </template>
      </BaseSelect>

      <SelectTab :options="levelsList" :value="level.id" @select="changeLevel">
        <template #option="slotProps">
          {{ $t(slotProps.option.value) }}
        </template>
      </SelectTab>
    </div>

    <ScrollSlider
      :items="$tm(`level_section.levels.${level.value}.benefits`) as []"
    >
      <template #scroll-slider-item="{ item }">
        <p>
          <BaseIcon :icon="$rt(item.item.icon)" height="32" width="32" />
        </p>
        <p>
          {{
            $rt(item.item.description, benefitParameters.levels[level.value])
          }}
        </p>
        <div v-if="item.index === 0">
          <LocLink :to="`/facilities?level=${level.id}`" @click="sendNavEvent">
            {{ $t("Объекты подписки") }}
          </LocLink>
          <BaseIcon icon="arrow" />
        </div>
        <div v-if="item.index === 0">
          <a :href="`https://www.allsports.fit/by/price_details/#objects`" target="_blank">
            {{ $t("List of objects (table)") }}
          </a>
          <BaseIcon icon="arrow" />
        </div>
      </template>
    </ScrollSlider>
  </section>
</template>
<script lang="ts">
import { PropType } from "vue";
import { Level } from "~/types/entities";
import BaseSelect, {
  BaseSelectOption,
} from "~/components/input/BaseSelect.vue";
import ScrollSlider from "~/components/slider/ScrollSlider.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import SelectTab from "~/components/input/SelectTab.vue";
import LocLink from "~/components/lang/LocLink.vue";
import { LevelsList } from "~/types/statistics";

interface Statistics {
  levels: LevelsList;
}

export default defineNuxtComponent({
  components: {
    SelectTab,
    ScrollSlider,
    BaseSelect,
    BaseIcon,
    LocLink,
  },
  props: {
    benefitParameters: {
      type: Object as PropType<Statistics>,
      default: {},
    },
  },

  setup(props) {
    const { gtag } = useGtag();
    const levelsList: BaseSelectOption[] = Object.keys(
      props.benefitParameters.levels
    ).map((level) => ({ id: level, value: level }));

    const level = ref<BaseSelectOption>(
      levelsList.find((e) => e.id === Level.PLATINUM) || levelsList[0]
    );

    function changeLevel(levelOption: BaseSelectOption | string) {
      if (typeof levelOption === "string") {
        gtag("event", LevelsEvents[levelOption as Level]);
        level.value = levelsList.find(
          (e) => e.id === levelOption
        ) as BaseSelectOption;
        return;
      }
      gtag("event", LevelsEvents[levelOption.id as Level]);
      level.value = levelOption;
    }

    const sendNavEvent = () =>
      gtag("event", LevelsEvents.toFacilitiesPage, { level: level.value.id });

    return {
      level,
      levelsList,
      sendNavEvent,
      changeLevel,
      benefitParameters: props.benefitParameters,
    };
  },
});
</script>
